import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import Alert from '../../components/Alert/alert';
import Date from '../../components/Post/date';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import PostsNavigation from '../../components/Post/posts-navigation';
import heroImg from '../../images/posts/005/powerful-start.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeroPost gradientFrom="#d4fc79" gradientTo="#96e6a1" mdxType="HeroPost">
  <img src={heroImg} alt="People just before a start" height="228" />
    </HeroPost>
    <h1>{`Useful setup that I always use when starting a new project`}</h1>
    <Date date="November 16, 2020" dateMachine="2020-11-16" mdxType="Date" />
    <p>{`I'm not a huge fan of "the art of the start" articles because, after all, `}<strong parentName="p">{`starting in a non-optimal way is worth more
than not starting at all`}</strong>{` with a head full of knowledge that wasn't applied.`}</p>
    <p>{`With that said, I still have a list of useful stuff that was proven to be working extremely well. The setup is meant to
remove some worries from your head and focus on the product/project.`}</p>
    <h2>{`1. Use version control - git`}</h2>
    <p>{`We start with an obvious one. Even if you don't work on software, use version control, and commit regularly.
It will pay off when you make mistakes (everyone does them).
When it comes to my projects, I always have to add `}<inlineCode parentName="p">{`.idea`}</inlineCode>{` to `}<inlineCode parentName="p">{`.gitignore`}</inlineCode>{` because I'm using WebStorm 🙄`}</p>
    <h2>{`2. Use .editorconfig`}</h2>
    <p>{`Tabs vs spaces? Inconsistent indent between different files or between team members? There is a
`}<a parentName="p" {...{
        "href": "https://editorconfig.org/"
      }}>{`well-established solution`}</a>{` for that.
Even if you are the only person working on the project, do yourself a favor and share `}<inlineCode parentName="p">{`.editorconfig`}</inlineCode>{` between your projects.
Most IDEs will read that file and keep the format of files the way you want.`}</p>
    <p>{`My personal preference is to apply a consistent style to ALL types of files by setting a small indent,
trimming whitespaces, and inserting a new line at the end of the file:`}</p>
    <pre><code parentName="pre" {...{}}>{`root = true

[*]
end_of_line = lf
charset = utf-8
trim_trailing_whitespace = true
insert_final_newline = true
indent_size = 2
`}</code></pre>
    <h2>{`3. Add linters`}</h2>
    <p>{`Linters everywhere! Really.`}</p>
    <p>{`Linter is a tool (script) that checks if you keep a certain "style" in your files. That is enforced by following the rules.
Some rules will try to keep you out of trouble, where the community knows that certain construction can harm you. And some
others will enforce visual style. That's good as well because it ends useless discussions about the style of code,
removes inconsistency and frustration within the team.`}</p>
    <p>{`A lot of rules comes with autofixable feature that can modify the code for you. Not only pointing what's wrong.`}</p>
    <p><strong parentName="p">{`Linters that I use:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://eslint.org/"
        }}>{`ESLint`}</a>{` - for JavaScript`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/remarkjs/remark-lint"
        }}>{`Markdown linter`}</a>{` - for `}<inlineCode parentName="li">{`.md`}</inlineCode>{` files`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stylelint.io/"
        }}>{`Stylelint`}</a>{` - for CSS`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/azeemba/eslint-plugin-json"
        }}>{`eslint-plugin-json`}</a>{` - technically it's still ESLint but worth mentioning
if you happen to work w `}<inlineCode parentName="li">{`.json`}</inlineCode>{` files (like translations or configuration files)`}</li>
    </ul>
    <p>{`Remember to update your linter dependencies regularly and check their release notes for new rules!`}</p>
    <Alert mdxType="Alert">
  <a href="https://palantir.github.io/tslint/" target="_blank" rel="noopener">
    TSLint
  </a>{' '}
  would be something worth mentioning as well (to analyze TypeScript syntax) but
  it was deprecated in 2019 if someone didn't notice. The proper way of using it
  now is{' '}
  <a href="https://github.com/typescript-eslint/typescript-eslint" target="_blank" rel="noopener">
    typescript-eslint
  </a>
  .
    </Alert>
    <h2>{`4. Add Prettier`}</h2>
    <p><a parentName="p" {...{
        "href": "https://prettier.io/"
      }}>{`Prettier`}</a>{` is an opinionated code formatter. Linters do affect the way your code looks but prettier
is much more in terms of styling the code. It offers support for multiple languages. The reason to use it is similar
to linters - `}<strong parentName="p">{`the code should look like written by a single person`}</strong>{`. Consistency is worth aiming for because it makes it
easier to read the files.`}</p>
    <p>{`Believe or not but developers read and think about code more than they actually write.`}</p>
    <h2>{`5. Automate it!`}</h2>
    <p>{`Having linters and prettier running in Continues Integration is a must. If the rules are violated then the build should fail.
The bigger the project is the longer it takes to analyze the whole codebase. `}<strong parentName="p">{`I am a big fan of linting only the files that
were changed`}</strong>{`. So I am using:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/typicode/husky"
        }}>{`Husky`}</a>{` - to manage git hooks,`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/okonet/lint-staged"
        }}>{`Lint staged`}</a>{` - to run linters only against files that were changed.`}</li>
    </ul>
    <p>{`How does it work? Husky reacts on git hook before committing files. Lint staged runs linters. Here is an example from
`}<inlineCode parentName="p">{`package.json`}</inlineCode>{` of one of my projects:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"husky": {
  "hooks": {
    "pre-commit": "lint-staged"
  }
},
"lint-staged": {
  "*.js": [
    "prettier --write",
    "eslint --cache --fix"
  ],
  "*.css": "stylelint \\"src/**/*.css\\" --fix"
}
`}</code></pre>
    <p>{`In one of the projects I'm working on, linting JS files take `}<strong parentName="p">{`more than 50 seconds`}</strong>{` when run against the whole project.
Running it only against files that were modified within a commit takes close to nothing. Here is how it looks when I commit
something to my blog:`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4f5d970a68c7e51c9b46b627d47a549f/b3931/lint-staged.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAxklEQVQY05WQ2w6CMBBEd9tyKSDQBo1yERJMRPz/7xuhGCIxRn2YtJudnsyUOA1BpCBZwRcSigUkMXzPR24MstzAGLsqCEIo5TlJqaa3vMrN1XjHoelxskd0RYFzsUdlC4RBgFBraB05SJLsEEWxO5Pdcp/3RPQEElgI0DjccOl7GGuh4wRCeqvhN/HqF1NDquoaTdOirhuUZeUSzQaeqr/W+SzaAq/DgGFK2bYd2q7b/NM2wXewA6ZphniuOg9vVfkP6AJ8AFgDgWxiceJVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Lint staged at work",
            "title": "Lint staged at work",
            "src": "/static/4f5d970a68c7e51c9b46b627d47a549f/d9199/lint-staged.png",
            "srcSet": ["/static/4f5d970a68c7e51c9b46b627d47a549f/8ff5a/lint-staged.png 240w", "/static/4f5d970a68c7e51c9b46b627d47a549f/e85cb/lint-staged.png 480w", "/static/4f5d970a68c7e51c9b46b627d47a549f/d9199/lint-staged.png 960w", "/static/4f5d970a68c7e51c9b46b627d47a549f/07a9c/lint-staged.png 1440w", "/static/4f5d970a68c7e51c9b46b627d47a549f/29114/lint-staged.png 1920w", "/static/4f5d970a68c7e51c9b46b627d47a549f/b3931/lint-staged.png 1931w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Lint staged at work`}</figcaption>{`
  `}</figure>
    <p>{`I understand some people like to experiment and don't always have clean commits that will pass linting. Fortunately,
you can bypass the checks by adding `}<inlineCode parentName="p">{`--no-verify`}</inlineCode>{` argument. So for example:`}</p>
    <pre><code parentName="pre" {...{}}>{`git commit -m "Blog Post 005: Powerful start" --no-verify
`}</code></pre>
    <h2>{`6. Setup CI to deploy quickly`}</h2>
    <p>{`That's another thing worth investing time in the beginning. `}<strong parentName="p">{`The easier it is to deploy the better.`}</strong>{` Of course, set
the pipeline and deploy only when tests are passing and linters are satisfied. I'm not giving any recipe this time
because it differs between projects. If you use a service like `}<a parentName="p" {...{
        "href": "https://www.netlify.com/"
      }}>{`Netlify`}</a>{` then it should be super easy to do it.
If you use something else invest the time to set up the process.`}</p>
    <p>{`For open-source projects, I use `}<a parentName="p" {...{
        "href": "https://travis-ci.org/"
      }}>{`Travis CI`}</a>{` and for commercial (indie) projects I use
`}<a parentName="p" {...{
        "href": "https://circleci.com/"
      }}>{`CircleCI`}</a>{`. I think Travis is completely free for opensource and Circle has a quite generous free plan
(`}<a parentName="p" {...{
        "href": "https://circleci.com/pricing/"
      }}>{`2,500 credits/week`}</a>{`) that will be enough for a side-project.`}</p>
    <p>{`In some cases, I have used GitHub Actions, like when I had to deploy a single static file to S3.`}</p>
    <h2>{`7. Dependabot`}</h2>
    <p>{`Updating your dependencies is important because you get security fixes and regular bugs fix. But who has time to remember
about it? No-one. So it's better to automate that process. If you happen to have tests in place with decent coverage then
ideally you should be able to just merge Pull Requests created by `}<a parentName="p" {...{
        "href": "https://dependabot.com/"
      }}>{`Dependabot`}</a>{`.`}</p>
    <p>{`The tool was acquired by GitHub in May 2019 and since then it is offered free of charge.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`That's it. A few tools, a few configuration files, maybe two services to keep
your project great and ready for fast deployment. `}<strong parentName="p">{`I found myself adding
these ingredients to every project I work on`}</strong>{`: server-side (in nodejs),
React, Ember, Angular, standalone library, `}<a parentName="p" {...{
        "href": "https://checknlearn.com"
      }}>{`Chrome extension`}</a>{`, React Native application,
and so on. As you see, it's not bounded to any technology so it's hard to come
up with a starter pack.`}</p>
    <p>{`You can set the sails now.`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://twitter.com/tomekdev_/status/1328255424907390977/" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Effective titles and more" prevLink="/posts/effective-titles-and-more" nextTitle="Search with typo tolerance" nextLink="/posts/search-with-typo-tolerance" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      